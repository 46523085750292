'use strict';

// #SNOT
// Not SFRA 6x script upgrade compatible.Selective changes applied.

var formValidation = require('theme/js/components/formValidation');
var createErrorNotification = require('theme/js/components/errorNotification');
var util = require('improove/util/util');

var login = require('base/login/login');

import featureFlag from 'shared/js/featureFlagRegistrar';

login.login = function () {
    $('[data-login-form]').submit(function (e) {
        e.preventDefault();
        var form = $(this);
        var url = form.attr('action');
        $(this).find('.login-button').prop('disabled', true);
        $('[data-login-form]').trigger('login:submit', e);
        grecaptcha.ready(function () { // eslint-disable-line
            grecaptcha.execute(form.attr('data-recaptcha-site-key'), { action: 'login' }).then(function (token) { // eslint-disable-line
                $.ajax({
                    url: url,
                    type: 'post',
                    dataType: 'json',
                    data: form.serialize() + '&token=' + token + '&action=login',
                    success: function (data) {
                        if (!data.success) {
                            formValidation(form, data);
                            $('[data-login-form]').trigger('login:error', data);
                            $('.login-button').prop('disabled', false);
                        } else {
                            $('[data-login-form]').trigger('login:success', data);
                            location.href = data.redirectUrl;
                            // $('.login-button').prop('disabled', false);
                        }
                    },
                    error: function (data) {
                        if (data.responseJSON.redirectUrl) {
                            window.location.href = data.responseJSON.redirectUrl;
                            $('.login-button').prop('disabled', false);
                        } else {
                            $('[data-login-form]').trigger('login:error', data);
                            $('.login-button').prop('disabled', false);
                        }
                    }
                });
            });
        });
        return false;
    });
};

// #CDIFF #DONE FM Flag support - do not allow registration
if (featureFlag.get('customer.registration.enabled')) {
    login.register = function () {
        $('[data-registration-form]').submit(function (e) {
            e.preventDefault();
            var form = $(this);
            var url = form.attr('action');
            $('[data-registration-form]').trigger('login:register', e);
            grecaptcha.ready(function () { // eslint-disable-line
                grecaptcha.execute(form.attr('data-recaptcha-site-key'), { action: 'createaccount' }).then(function (token) { // eslint-disable-line
                    $.ajax({
                        url: url,
                        type: 'post',
                        dataType: 'json',
                        data: form.serialize() + '&token=' + token + '&action=createaccount',
                        success: function (data) {
                            if (!data.success) {
                                $('form.registration').trigger('login:register:error', data);
                                formValidation(form, data);
                            } else {
                                $('form.registration').trigger('login:register:success', data);
                                location.href = data.redirectUrl;
                            }
                        },
                        error: function (err) {
                            if (err.responseJSON.redirectUrl) {
                                window.location.href = err.responseJSON.redirectUrl;
                            } else {
                                createErrorNotification($('.error-messaging'), err.responseJSON.errorMessage);
                            }
                        }
                    });
                });
            });
            return false;
        });
    };

    // #ADDIN #CDIFF #DONE BS <> GG - No in BS, but present in GG
    if (featureFlag.get('customer.registration.registrationTabFlag')) {
        login.openRegistrationTab = function () {
            $('body').on('click', '[data-open-register-tab]', function (e) {
                e.preventDefault();

                if (!$(this).hasClass('active')) {
                    // Remove class Active from other Tabs
                    $('[data-tab-header]').removeClass('active');
                    $('[data-tab-content]').removeClass('active');

                    // Set Tab Header Item as Active
                    $(this).addClass('active');

                    // Set Tab Content Item as Active
                    var clickedTab = $(this).data('tab-header');
                    $('[data-tab-header=' + clickedTab + ']').addClass('active');
                    $('[data-tab-content=' + clickedTab + ']').addClass('active');
                    window.scrollTo(0, 0);
                }
            });
        };
    }

    // #CDIFF #DONE FM Flag support - do not allow registration
    login.addActiveClass = function () {
        if (util.getScreenSize() !== 'small' && util.getScreenSize() !== 'medium') {
            $('[data-tab-header="tab-1"]').addClass('active');
            $('[data-tab-content="tab-1"]').addClass('active');
        }
    };
}

module.exports = login;
